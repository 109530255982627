import React from 'react';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  classNames?: string;
  customLabelClass?: string;
}

export default function Checkbox(props: CheckboxProps) {
  const { onChange, checked, name, label, value, classNames, customLabelClass } = props;

  const labelClassNames = `form-checkbox-label align-items-center p-md-l-sm p-md-r p-y-xs ${
    classNames ?? ''
  }`;

  return (
    <>
      <input
        type={'checkbox'}
        id={value as string}
        name={name}
        onChange={onChange}
        checked={checked}
        value={value}
        className="form-checkbox form-checkbox-sm"
      />
      <label
        htmlFor={value as string}
        className={customLabelClass ? customLabelClass : labelClassNames}>
        {label}
      </label>
    </>
  );
}
