import React, { useContext, useEffect } from 'react';
import UnsubscribeForm from './UnsubscribeForm';
import Logout from '../common/Logout';
import { UserContext } from '../app/UserContext';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../app/AppContext';
import { page, PAGE_NAMES } from '../../analytics';

export default function Unsubscribe() {
  const { user } = useContext(UserContext);
  const { setStep } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    page(PAGE_NAMES.UNSUBSCRIBE);
  }, []);

  return (
    <>
      <div>
        {t('unsubsribe_for_email', { email: user.email })}
        <Logout text={t('switch_account')} />.
      </div>
      <UnsubscribeForm />
      <div className="m-t-lg">
        <h3>{t('customize_communication')}</h3>
        <p>{t('want_to_receive_updates')}</p>
        <a
          onClick={() => setStep('selectPreferences')}
          className="cursor-pointer text-decoration-none text-700">
          {t('manage_my_preferences')}
        </a>
      </div>
    </>
  );
}
