import React from 'react';
import IconsVision from '../../assets/svg/icons-vision.svg';

export const IconTypeMap = {
  vision: IconsVision,
  default: IconsVision
};

// We currently only require vision,
// but will likely need to include other types
type IconType = 'vision';

export default function Icon(props: {
  name: string;
  className?: string;
  style?: React.CSSProperties;
  type?: string;
}) {
  const type = props?.type ? props.type : 'vision';
  const iconType = Object.keys(IconTypeMap).includes(type)
    ? IconTypeMap[type as IconType]
    : IconTypeMap.default;

  return (
    <svg className={`icon ${props.name} ${props.className ?? ''}`} style={props.style}>
      <use xlinkHref={`${iconType}#${props.name}`}></use>
    </svg>
  );
}
