import React from 'react';

interface Option {
  label: string;
  value: string;
}

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  reqError?: boolean;
  label: string;
  options: Array<Option>;
}

export default function Select(props: SelectProps): JSX.Element {
  const { reqError, onChange, value, id, label, options } = props;

  const renderOptions = () =>
    options.map(option => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));

  return (
    <>
      <label htmlFor={id}>
        {reqError && (
          <span aria-label="required">
            <span aria-hidden="true">*</span>
          </span>
        )}
        {label}
      </label>
      <div className="form-select display-block">
        <select
          id={id}
          className="display-block"
          style={{ width: '100%' }}
          onChange={onChange}
          value={value}>
          {renderOptions()}
        </select>
      </div>
    </>
  );
}
