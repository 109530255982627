import React, { useContext, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsentField, ConsentStatus } from '../../@types/api';
import { PreferenceContext } from './PreferenceContext';
import { UserContext } from '../app/UserContext';
import Select from '../common/Inputs/Select';
import NoticeBox from './NoticeBox';

const WIDGET_ORDER = [ConsentField.COUNTRY];

export default function () {
  const { t } = useTranslation();
  const { countries, formValues, setFormValues } = useContext(PreferenceContext);
  const {
    user: { fields }
  } = useContext(UserContext);

  const buildUpdateFormValues =
    (field: ConsentField) => (event: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      return setFormValues({ ...formValues, [field]: event.currentTarget.value });
    };
  const widgets: { [key in ConsentField]: () => ReactNode | null } = {
    [ConsentField.COUNTRY]: () => (
      <Select
        reqError={!Boolean(formValues[ConsentField.COUNTRY])}
        required={true}
        id={'country'}
        label={t('country')}
        options={[{ label: '', value: '' }, ...countries]}
        onChange={buildUpdateFormValues(ConsentField.COUNTRY)}
      />
    )
  };
  const visible = WIDGET_ORDER.filter(field => fields.includes(field)).map(field =>
    widgets[field]()
  );

  // 2 column layout
  const renderTable = () => {
    const table = [];
    for (let i = 0; i < visible.length; i += 2) {
      table.push(
        <div key={i} className="row m-x-0">
          <div className="col-md-3 col-xs-12 p-l-0">
            <div className="form-group form-group-float">{visible[i]}</div>
          </div>
          <div className="col-md-3 col-xs-12 p-l-0">
            {visible[i + 1] ? (
              <div className="form-group form-group-float">{visible[i + 1]}</div>
            ) : null}
          </div>
        </div>
      );
    }
    return table;
  };

  return (
    <>
      {visible.length !== 0 && (
        <div id="confirmIdentity">
          <div className="row m-x-0 p-t-md text-700">
            <h3>{t('select_your_country_head')}</h3>
          </div>
          {renderTable()}
        </div>
      )}
      <NoticeBox />
    </>
  );
}
