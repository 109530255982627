import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { initTranslations } from '../app/utils';
import { getLocale } from '../../locale';
import NotFoundComponent from './Error404';
import Loader from '../common/Loader';

export default function NotFound404(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const pathParams = useParams();

  useEffect(() => {
    (async () => {
      await initTranslations(pathParams.locale ?? getLocale());
      setIsLoading(false);
    })();
  }, []);

  return isLoading ? <Loader /> : <NotFoundComponent />;
}
