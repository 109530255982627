import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export default function NotFoundComponent(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="container p-y-lg p-lg-y-xl flex-1" style={{ marginTop: 80 }}>
      <div className="row align-items-center">
        <div className="col-md-6 col-xs-12">
          <h1 className="text-kicker">404 Error</h1>
          <p className="header-focus display-3 line-height-1-2 text-700">
            <span>{t('pc_404_header')}</span>
          </p>
          <p>{t('pc_404_tagline')}</p>
          <p>{t('pc_404_title')}</p>
          <ul style={{ paddingInlineStart: 40 }}>
            <li>{t('pc_404_description')}</li>
            <li>
              <Trans
                i18nKey="pc_404_link"
                components={{
                  a: <a href="https://community.sw.siemens.com/s/" />
                }}
              />
            </li>
          </ul>
        </div>
        <div className="col-md-6 col-xs-12">
          <img src="https://images.sw.cdn.siemens.com/shared/404.svg" alt="404/Not Found Image" />
        </div>
      </div>
    </div>
  );
}
