import React, { useState } from 'react';
import Domains from './Domains';
import Industries from './Industries';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('industries');

  return (
    <>
      <div
        className="tablist text-sm text-md-lg"
        role="tablist"
        aria-label="Tabs short description"
        aria-orientation="horizontal">
        <ul id="tablist-tabs" className="accordion-nav">
          <li
            className={activeTab === 'industries' ? 'active' : ''}
            onClick={() => setActiveTab('industries')}>
            {t('industries_heading')}
          </li>
          <li
            className={activeTab === 'solutions' ? 'active' : ''}
            onClick={() => setActiveTab('solutions')}>
            {t('solutions_heading')}
          </li>
        </ul>
      </div>
      {activeTab === 'industries' && <Industries />}
      {activeTab === 'solutions' && <Domains />}
    </>
  );
};
