import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  customClass?: string;
  enableSpinner?: boolean;
}

export default function Button(props: ButtonProps) {
  // btn-outline button style option for simple outline button
  const {
    type = 'button',
    text,
    customClass = 'btn-sm btn-primary mobile-btn-block',
    onClick,
    enableSpinner = false,
    disabled
  } = props;

  return (
    <button disabled={disabled} onClick={onClick} type={type} className={`btn ${customClass}`}>
      {disabled && enableSpinner && <div className="loader-spinner spinner-button"></div>}
      {text}
    </button>
  );
}
