import React, { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { locales } from '../../locale';
import { AppContext, Step } from './AppContext';
import { UserContext, UserPreferences } from './UserContext';
import { authenticate, getUserPreferences } from '../../api';
import { getPreferencePayload } from '../../@types/api';
import Layout from '../Layout';
import Loader from '../common/Loader';
import { initTranslations } from './utils';
import { getLocale } from '../../locale';
import { AxiosError } from 'axios';
import { DatadogRum } from '../../rum';

DatadogRum();

function App() {
  const { setStep, isLoading, setIsLoading, setLocale } = useContext(AppContext);
  const { setUser } = useContext(UserContext);
  const location = useLocation();
  const pathParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [unsubscribe] = useState<string | null>(searchParams.get('unsubscribe'));
  const [pathLocale] = useState<string | undefined>(pathParams.locale);
  const [showConfirmation] = useState<boolean | undefined>(
    location.pathname.endsWith('/subscription-confirmation')
  );

  useEffect(() => {
    (async () => {
      const { authenticated } = await authenticate();

      if (authenticated) {
        searchParams.forEach((_, key) => {
          // allow ?unsubscribe=true to remain
          if (key !== 'unsubscribe') searchParams.delete(key);
        });
      }

      // redirect to default locale if no locale in URL
      if (!pathParams?.locale) {
        navigate(`/${getLocale()}${location.pathname}?${searchParams.toString()}`);
      }

      // redirect if we get an unsupported locale
      else if (!locales.includes(pathParams?.locale ?? '')) {
        navigate(`/${getLocale()}/404`);
      }

      // append a trailing slash if we don't have one
      else if (!showConfirmation && !location.pathname.endsWith('/')) {
        navigate(`${location.pathname}/?${searchParams.toString()}`);
      }

      // reset searchParams if they've changed
      else if (unsubscribe || authenticated) {
        setSearchParams(searchParams);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const getPathUrl = pathLocale ?? getLocale();
      await initTranslations(getPathUrl);

      const { authenticated, sid, user } = await authenticate();

      const unsubscribeQueryObj: { [key: string]: Step } = {
        true: 'unsubscribe',
        false: 'selectPreferences',
        default: 'selectPreferences'
      };

      setLocale(getPathUrl);

      if (authenticated && !showConfirmation) {
        const payload: getPreferencePayload = {
          contactId: user.contact_id,
          jwt: user.jwt,
          uid: user.uid
        };

        try {
          const {
            account: { preferences, optStatus },
            fields = []
          } = await getUserPreferences(payload);

          const data: UserPreferences = {
            acceptEnglish: preferences?.acceptEnglish === true,
            domains: preferences?.domains ? preferences.domains : {},
            industries: preferences?.industries ? preferences.industries : {},
            preferredLocale: preferences?.preferredLocale ?? getPathUrl
          };

          if (user) {
            setUser({
              ...user,
              contactId: user.contact_id,
              sessionId: sid,
              isAuthenticated: authenticated,
              preferences: data,
              fields,
              optStatus
            });
          }

          setStep(unsubscribeQueryObj[unsubscribe || 'default']);
        } catch (error: unknown) {
          // TODO: How do we want to handle a failure here??
          const notificationType =
            (error instanceof AxiosError && error.response?.data?.notificationType) || 'FAILURE';

          if (notificationType === 'EMBARGOED') {
            setStep('embargoed');
          }
        }
      } else if (searchParams.get('sig') && searchParams.get('cid')) {
        const payload: getPreferencePayload = {
          contactId: searchParams.get('cid') ?? '',
          sig: searchParams.get('sig') ?? ''
        };

        try {
          const {
            account: { preferences, optStatus, email },
            fields = []
          } = await getUserPreferences(payload);

          const data: UserPreferences = {
            acceptEnglish: preferences?.acceptEnglish === true,
            domains: preferences?.domains ? preferences.domains : {},
            industries: preferences?.industries ? preferences.industries : {},
            preferredLocale: pathLocale ?? preferences?.preferredLocale ?? getLocale()
          };

          setUser({
            sig: searchParams.get('sig') ?? '',
            contactId: searchParams.get('cid') ?? '',
            isAuthenticated: true,
            preferences: data,
            email,
            fields,
            optStatus
          });

          setStep(unsubscribeQueryObj[unsubscribe || 'default']);
        } catch (error: any) {
          // TODO: How do we want to handle a failure here??
          console.error('Authorization Error: Contact-Id and Sig', error);
        }
      } else if (showConfirmation) {
        setStep('subscriptionConfirmation');
      } else if (unsubscribe) {
        setStep('unsubscribeByEmail');
      }

      setIsLoading(false);
    })();
  }, [pathLocale]);

  return isLoading ? <Loader /> : <Layout />;
}

export default App;
