import React, { useState, createContext, useContext, useEffect } from 'react';
import { DEFAULT_LOCALE, getLocale } from '../../locale';
import { UserContext } from '../app/UserContext';
import { getPreferenceSets, Domain } from '../../api/prefs';
import { Domains, Industries, ConsentField } from '../../@types/api';
import { getCountries, Country } from '../../api/fieldMarshal';

type TranslationsMap = {
  businessAreas: { [key: string]: string };
  domains: { [key: string]: string };
  industries: { [key: string]: string };
};

const defaultTranslationsMap = {
  businessAreas: {},
  domains: {},
  industries: {}
};

export type FormValues = { [key in ConsentField]: string };

export interface PreferenceContextInterface {
  translationsMap: TranslationsMap;
  preferredLocale: string;
  setPreferredLocale: React.Dispatch<React.SetStateAction<string>>;
  acceptEnglish: boolean;
  setAcceptEnglish: React.Dispatch<React.SetStateAction<boolean>>;
  domains: Domains;
  setDomains: React.Dispatch<React.SetStateAction<Domains>>;
  industries: Industries;
  setIndustries: React.Dispatch<React.SetStateAction<Industries>>;
  countries: Array<Country>;
  formValues: FormValues;
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  dunsNumber: string;
  setDunsNumber: React.Dispatch<React.SetStateAction<string>>;
}

export const PreferenceContext = createContext({} as PreferenceContextInterface);

export const PreferenceContextProvider = ({ children }: any) => {
  const {
    user: { preferences: userPreferences }
  } = useContext(UserContext);

  const userDomains = userPreferences.domains as { [key: string]: boolean };
  const userIndustries = userPreferences.industries as { [key: string]: boolean };
  const userPreferredLocale = userPreferences.preferredLocale;
  const userAcceptEnglish = userPreferences.acceptEnglish;

  const [domains, setDomains] = useState<Domains>({});
  const [industries, setIndustries] = useState<Industries>({});
  const [preferredLocale, setPreferredLocale] = useState<string>(
    userPreferredLocale ?? DEFAULT_LOCALE
  );
  const [acceptEnglish, setAcceptEnglish] = useState<boolean>(userAcceptEnglish);
  const [translationsMap, setTranslationsMap] = useState<TranslationsMap>(defaultTranslationsMap);
  const [countries, setCountries] = useState<Country[]>([]);
  const [formValues, setFormValues] = useState<FormValues>({} as FormValues);
  const [dunsNumber, setDunsNumber] = useState<string>('');

  useEffect(() => {
    const func = async (): Promise<void> => {
      const pathLocale = getLocale();
      const [{ domains: _domains, industries: _industries }, _countries] = await Promise.all([
        getPreferenceSets(pathLocale),
        getCountries(pathLocale)
      ]);

      setCountries(_countries.map(({ label, code }) => ({ label, value: code })));

      // TODO: have api data publisher generate this map as the en-US.json
      const domainTranslations = _domains.reduce(
        (acc: any, domain: any) => ({
          ...acc,
          businessAreas: {
            ...acc.businessAreas,
            [domain.businessArea.id]: domain.businessArea.name
          },
          domains: {
            ...acc.domains,
            [domain.id]: domain.name
          }
        }),
        translationsMap
      );
      setTranslationsMap(domainTranslations);

      // TODO: Have api do this transformation and cache data structure
      const initDomains = (_domains as Domain[]).reduce(
        (acc: Domains, domain: Domain) => ({
          ...acc,
          [domain.businessArea.id]: {
            ...acc[domain.businessArea.id],
            [domain.id]: userDomains[domain.id] || false
          }
        }),
        {}
      ) as Domains;
      setDomains(initDomains);

      // TODO: have api data publisher generate this map as the en-US.json
      const industryTranslations = _industries.reduce(
        (acc: any, curr: any) => ({
          ...acc,
          industries: {
            ...acc.industries,
            [curr.id]: curr.name
          }
        }),
        translationsMap
      );
      setTranslationsMap({
        ...domainTranslations,
        industries: industryTranslations.industries
      });

      // TODO: Have api do this transformation and cache data structure
      const initIndustries = _industries.reduce(
        (acc: any, curr: { id: string; name: string }) => ({
          ...acc,
          [curr.id]: userIndustries[curr.id]
        }),
        {}
      );
      setIndustries(initIndustries);
    };
    func();
  }, []);

  const value = {
    acceptEnglish,
    countries,
    domains,
    dunsNumber,
    formValues,
    industries,
    preferredLocale,
    setAcceptEnglish,
    setDomains,
    setDunsNumber,
    setFormValues,
    setIndustries,
    setPreferredLocale,
    translationsMap
  };

  return <PreferenceContext.Provider value={value}>{children}</PreferenceContext.Provider>;
};
