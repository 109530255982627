import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { AppContext } from '../app/AppContext';

export default function () {
  const { locale } = useContext(AppContext);
  const consentURL = `https://www.sw.siemens.com/${locale ?? 'en-US'}/consent-declaration/`;
  return (
    <div className="row m-x-0 p-y-md ">
      <div>
        <Trans
          i18nKey="pc_notification_box"
          components={{
            s: <strong />,
            a: <a target="_blank" className="text-decoration-none text-700" href={consentURL} />
          }}
        />
      </div>
    </div>
  );
}
