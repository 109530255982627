import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../app/AppContext';
import { Step } from '../app/AppContext';

const headerCopy = (step: Step) => {
  const { t } = useTranslation();

  const headers = {
    signIn: {
      tagLine: t('manage_email_preferences'),
      headerText: t('update_or_unsubscribe')
    },
    selectPreferences: {
      tagLine: '',
      headerText: t('manage_email_preferences')
    },
    unsubscribe: {
      tagLine: t('manage_email_preferences'),
      headerText: t('unsubscribe')
    },
    unsubscribeByEmail: {
      tagLine: t('manage_email_preferences'),
      headerText: t('unsubscribe')
    },
    failure: {
      tagLine: t('manage_email_preferences'),
      headerText: t('update_or_unsubscribe')
    },
    embargoed: {
      tagLine: '',
      headerText: t('preference_not_available')
    },
    subscriptionConfirmation: {
      tagLine: t('manage_email_preferences'),
      headerText: t('signup_successful')
    }
  };

  return {
    signIn: headers.signIn,
    selectPreferences: headers.selectPreferences,
    unsubscribe: headers.unsubscribe,
    unsubscribeByEmail: headers.unsubscribeByEmail,
    failure: headers.failure,
    embargoed: headers.embargoed,
    subscriptionConfirmation: headers.subscriptionConfirmation
  }[step];
};

export default function Header() {
  const { step } = useContext(AppContext);

  const { tagLine, headerText } = headerCopy(step);

  return (
    <div>
      <div className="group-focus">
        {tagLine && <strong className="text-kicker">{tagLine}</strong>}
        {<h1>{headerText}</h1>}
      </div>
    </div>
  );
}
