import React, { useState } from 'react';

export interface AppContextInterface {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  step: Step;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
  locale: string;
  setLocale: React.Dispatch<React.SetStateAction<string>>;
}

export const defaultStep = 'signIn';

export type Step =
  | 'signIn'
  | 'selectPreferences'
  | 'unsubscribe'
  | 'failure'
  | 'embargoed'
  | 'subscriptionConfirmation'
  | 'unsubscribeByEmail';

export const AppContext = React.createContext({} as AppContextInterface);

export const AppContextProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [step, setStep] = useState<Step>(defaultStep);
  const [locale, setLocale] = useState<string>('en-US');

  const value = {
    isLoading,
    setIsLoading,
    step,
    setStep,
    locale,
    setLocale
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
