import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './components/app';
import NotFound404 from './components/NotFound404';
import { AppContextProvider } from '../src/components/app/AppContext';
import { UserContextProvider } from '../src/components/app/UserContext';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound404 />
  },
  {
    path: `/:locale`,
    element: <App />
  },
  {
    path: `/:locale/`,
    element: <App />
  },
  {
    path: `/:locale/subscription-confirmation`,
    element: <App />
  },
  {
    path: `/subscription-confirmation`,
    element: <App />
  }
]);

declare global {
  interface Window {
    analytics: any;
  }
}

const mountApp = () => {
  const preferenceCenterContainer = document.getElementById('preference-center') as HTMLElement;
  const root = ReactDOM.createRoot(preferenceCenterContainer);

  root.render(
    <React.StrictMode>
      <AppContextProvider>
        <UserContextProvider>
          <RouterProvider router={router} />
        </UserContextProvider>
      </AppContextProvider>
    </React.StrictMode>
  );
};

mountApp();
