import { AxiosResponse } from 'axios';
import { httpClient } from './utils';

export type Domain = {
  name: string;
  id: string;
  businessArea: {
    name: string;
    id: string;
  };
};

export type Industry = { name: string; id: string };

export type PreferenceSets = {
  domains: Array<Domain>;
  industries: Array<Industry>;
};

export const getPreferenceSets = async (locale: string): Promise<PreferenceSets> => {
  const response: AxiosResponse<any, PreferenceSets> = await httpClient({
    baseURL: '',
    url: `${process.env.CATEGORIES_CDN_BASE ?? ''}/categories/${locale}.json`,
    noCache: false
  });

  return response.data;
};
