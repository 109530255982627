import axios, { AxiosResponse } from 'axios';
import { httpClient } from './utils';
import {
  Account,
  AccountResponseData,
  Authenticate,
  getPreferencePayload,
  NotificationType,
  postPreferencePayload,
  TranslationMap,
  unsubscribeEmailPayload
} from '../@types/api';

// TODO: move to process.env default config
const DISW_ENVIRONMENT = process.env.DISW_ENVIRONMENT || 'dev';
const APP_NAME = 'preference-center';
const CDN_URL = process.env.CDN_URL || 'https://static.sw.cdn.siemens.com';
const PREFERENCE_CENTER_URL =
  process.env.PREFERENCE_CENTER_API ?? 'https://api.dev.swi.siemens.com/preferences';

const getRequestHeaders = ({ contactId, sessionId, jwt, uid, sig }: getPreferencePayload) => ({
  'x-pc-contact-id': contactId,
  ...(sessionId && { 'x-pc-session-id': sessionId }),
  ...(jwt && { 'x-pc-auth-token': jwt }),
  ...(uid && { 'x-pc-account-uid': uid }),
  ...(sig && { 'x-pc-auth-sig': sig })
});

const authenticate = (): Promise<Authenticate> => {
  return new Promise((resolve, reject) => {
    if (!window.disw?.account?.subscribeToAccount) {
      reject(new Error('DISW Utils not available'));
    }

    window.disw.account.subscribeToAccount(async (account: Account, error: Error) => {
      return account ? resolve(account) : reject(error);
    });
  });
};

const validateEmail = async (
  email: string,
  locale: string,
  unsubscribe = false
): Promise<NotificationType> => {
  const res = await axios.post<AccountResponseData>(
    `${PREFERENCE_CENTER_URL}/send-verification-email`,
    { email, locale, unsubscribe }
  );
  const { notificationType } = res.data;
  return notificationType as NotificationType;
};

const getTranslations = async (locale: string): Promise<AxiosResponse<any, TranslationMap>> =>
  await httpClient({
    baseURL: CDN_URL,
    noCache: false,
    url: `/translations/${DISW_ENVIRONMENT}/${APP_NAME}/translation-${locale}`
  });

const getUserPreferences = async (options: getPreferencePayload): Promise<AccountResponseData> => {
  const res = await axios.get<AccountResponseData>(PREFERENCE_CENTER_URL, {
    headers: getRequestHeaders(options)
  });
  const { account, fields } = res.data;
  return { account, fields };
};

const postUserPreferences = async (options: postPreferencePayload): Promise<NotificationType> => {
  const res = await axios.post<AccountResponseData>(
    `${PREFERENCE_CENTER_URL}/`,
    options.preferences,
    {
      headers: {
        ...getRequestHeaders(options),
        'Cache-Control': 'no-cache'
      }
    }
  );
  const { notificationType } = res.data;
  return notificationType as NotificationType;
};

const unsubscribe = async (options: unsubscribeEmailPayload): Promise<NotificationType> => {
  const res = await axios.post<AccountResponseData>(
    `${PREFERENCE_CENTER_URL}/unsubscribe`,
    {},
    {
      headers: getRequestHeaders(options)
    }
  );
  const { account, notificationType } = res.data;
  return notificationType as NotificationType;
};

const unsubscribeByEmail = () => {
  console.log('just a placeholder for now');
  return 'SUCCESS' as NotificationType;
};

export {
  authenticate,
  validateEmail,
  getTranslations,
  getUserPreferences,
  postUserPreferences,
  unsubscribe,
  unsubscribeByEmail
};
