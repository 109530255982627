import React, { useContext, useEffect } from 'react';
import { PreferenceContextProvider } from './PreferenceContext';
import PreferenceTable from './PreferenceTable';
import PreferredLanguage from './PreferredLanguage';
import SavePreferencesButton from './SavePreferencesButton';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../app/AppContext';
import { UserContext } from '../app/UserContext';
import ContactDetailsForm from './ContactDetailsForm';
import Logout from '../common/Logout';
import { page, PAGE_NAMES } from '../../analytics';

export default function SelectPreferences() {
  const { t } = useTranslation();
  const { setStep, locale } = useContext(AppContext);
  const { user } = useContext(UserContext);

  const privacyPolicyLink = `https://www.sw.siemens.com/${locale ?? 'en-US'}/privacy-policy/ `;

  useEffect(() => {
    page(PAGE_NAMES.SELECT_PREFERENCES);
  }, []);

  return (
    <>
      <div className="p-t-sm">
        <p>
          {t('manage_preferences_subhead')} {user.email}. {t('use_different_account')}?{' '}
          <Logout text={t('switch_account')} />.
        </p>
      </div>
      <p>
        {t('manage_preferences_instructions')}
        <a
          className="text-decoration-none text-700"
          target="_blank"
          rel="noreferrer"
          href={privacyPolicyLink}>
          {t('privacy_policy')}
        </a>
        .
      </p>

      <div className="preference-form p-t-md">
        <h3>{t('preference_table_heading')}</h3>
        <PreferenceContextProvider>
          <PreferenceTable />
          <div>
            <PreferredLanguage />
            <ContactDetailsForm />
            <SavePreferencesButton />
            <div className="row m-x-0 p-l-0 m-y-lg">
              <div className="text-sm">
                <h3>{t('unsubscribe')}</h3>
                {t('wish_to_unsubscribe')}
                <a
                  className="cursor-pointer text-decoration-none text-700"
                  onClick={() => setStep('unsubscribe')}>
                  {t('unsubscribe_page')}.
                </a>
              </div>
            </div>
          </div>
        </PreferenceContextProvider>
      </div>
    </>
  );
}
