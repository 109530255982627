import React, { useContext } from 'react';
import { PreferenceContext } from '../PreferenceContext';

const noop = () => {};

export default () => {
  const { translationsMap, setIndustries, industries } = useContext(PreferenceContext);
  const industryKeys = Object.keys(industries).sort();

  const handleOnClick = (industryKey: string) =>
    setIndustries({
      ...industries,
      [industryKey]: !industries[industryKey]
    });

  return (
    <div className="tabpanels m-l-0">
      <div className="TabContent">
        {industryKeys.map((industryKey, id) => (
          <div
            className="row p-y-sm m-x-sm border-b border-1 border-grey-lighter"
            onClick={() => handleOnClick(industryKey)}
            key={id}>
            <input
              type="checkbox"
              name="traits"
              id="aero"
              className="form-checkbox form-checkbox-sm"
              onChange={noop}
              checked={industries[industryKey]}
            />
            <label className="form-checkbox-label align-items-center text-sm p-md-l-sm p-md-r p-y-xs">
              {translationsMap.industries[industryKey]}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
