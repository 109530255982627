import { datadogRum } from '@datadog/browser-rum';

export const DatadogRum = () => {
  datadogRum.init({
    applicationId: '0b086fc8-1a2f-4b35-a52c-98159124f718',
    clientToken: 'pub96506a25713b637aa9565af779e09abb',
    site: 'datadoghq.com',
    service: 'disw-preference-center',
    env: process.env.DISW_ENVIRONMENT,
    version: '1.0.0',
    sessionSampleRate: 50,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  datadogRum.startSessionReplayRecording();
};
