import { useTranslation } from 'react-i18next';

export const DEFAULT_LOCALE = 'en-US';

// This isn't in any real order, but we should be sorting it in code
// when displayed according to whatever locale we're currently in.
export const languages = [
  { label: 'locale_en', value: 'en-US' },
  { label: 'locale_de', value: 'de-DE' },
  { label: 'locale_ru', value: 'ru-RU' },
  { label: 'locale_ja', value: 'ja-JP' },
  { label: 'locale_pt', value: 'pt-BR' },
  { label: 'locale_ko', value: 'ko-KR' },
  { label: 'locale_zh', value: 'zh-CN' },
  { label: 'locale_cz', value: 'cs-CZ' },
  { label: 'locale_fr', value: 'fr-FR' },
  { label: 'locale_it', value: 'it-IT' },
  { label: 'locale_es', value: 'es-ES' },
  { label: 'locale_pl', value: 'pl-PL' },
  { label: 'locale_zh_tw', value: 'zh-TW' }
];

export const locales = languages.map(({ value }) => value);

export const getLocale = (): string => {
  const browserLocale = window?.disw?.locales?.getCurrentLocaleCode?.() ?? '';

  if (locales.includes(browserLocale)) {
    return browserLocale;
  }

  return DEFAULT_LOCALE;
};

export const getTranslatedLanguages = () => {
  const { t } = useTranslation();

  const translatedLanguages = languages
    .map(({ label, value }) => ({
      label: t(label),
      value
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  return translatedLanguages;
};
