import React, { useState } from 'react';
import Icon from './Icon';
import { NotificationType } from '../../@types/api';
import { useTranslation } from 'react-i18next';

type NotificationProps = {
  type: NotificationType;
  closeDisabled?: boolean;
  heading: string;
  body?: string;
  link?: {
    text: string;
    action: () => void;
  };
};

const types = {
  SUCCESS: {
    id: 'successComplex',
    icon: 'icon-success',
    class: 'alert-success'
  },
  FAILURE: {
    id: 'dangerComplex',
    class: 'alert-danger',
    icon: 'icon-warning'
  },
  WARNING: {
    id: 'warningSimple',
    class: 'alert-warning',
    icon: 'icon-warning'
  },
  INFO: {
    id: 'infoSimple',
    class: 'alert-info',
    icon: 'icon-information'
  },
  EMBARGOED: {
    id: 'dangerComplex',
    class: 'alert-danger',
    icon: 'icon-information'
  }
};

export default function Notification({
  type,
  heading,
  body,
  link,
  closeDisabled
}: NotificationProps): JSX.Element {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(true);

  return isVisible ? (
    <div className="row m-x-0">
      <div className="col-xs-12 col-md-10 p-x-0 p-xs-x-m">
        <div id={types[type].id}>
          <div className={`alert ${types[type].class} space-children p-a-md bg-white`}>
            <div className="m-r-sm">
              <Icon type="vision" name={types[type].icon} className="alert-icon m-r-sm" />
            </div>
            <div className="m-t-0">
              <span className="text-700">{t(heading)}</span>
              <p>{body && t(body)}</p>
              {link && (
                <a
                  className="button-link text-decoration-none text-700 item-flex text-600 cursor-pointer"
                  rel="noreferrer"
                  onClick={() => link.action()}
                >
                  {t(link.text)}
                  <Icon type="vision" name="icon-refresh" className="icon-normal m-l-sm" />
                </a>
              )}
            </div>
            {!closeDisabled && (
              <div
                className="m-l-auto m-t-0 cursor-pointer"
                aria-expanded="true"
                onClick={() => setIsVisible(false)}
              >
                <Icon type="vision" name="icon-close" className="icon icon-close icon-normal" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
