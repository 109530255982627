import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { getLocale } from '../locale';
import { page, PAGE_NAMES } from '../analytics';

export default function SubscriptionConfirmation() {
  const pathParams = useParams();
  const locale = pathParams.locale || getLocale();

  useEffect(() => {
    page(PAGE_NAMES.SUBSCRIPTION_CONFIRMATION);
  }, []);

  return (
    <Trans
      i18nKey="signup_success_message"
      components={{
        a1: (
          <Link to="https://www.sw.siemens.com/en-US/" title="https://www.sw.siemens.com/en-US/" />
        ),
        a2: <Link to={`/${locale}/`} title="Manage email preferences" reloadDocument />
      }}
    />
  );
}
