import React, { useState } from 'react';
import { ConsentField, ConsentStatus } from '../../@types/api';

export interface UserPreferences {
  acceptEnglish: boolean;
  domains: { [key: string]: boolean } | {};
  industries: { [key: string]: boolean } | {};
  preferredLocale: string;
}

export type UserState = {
  contactId: string;
  jwt?: string;
  uid?: string;
  sig?: string;
  email?: string;
  countryCode?: string;
  sessionId?: string;
  isAuthenticated: boolean;
  preferences: UserPreferences;
  fields: Array<ConsentField>;
  optStatus?: ConsentStatus;
};

export const defaultUserState: UserState = {
  contactId: '',
  jwt: '',
  uid: '',
  sig: '',
  email: '',
  countryCode: '',
  sessionId: '',
  isAuthenticated: false,
  preferences: {
    acceptEnglish: false,
    domains: {},
    industries: {},
    preferredLocale: ''
  },
  fields: []
};

export interface UserContextInterface {
  user: UserState;
  setUser: React.Dispatch<React.SetStateAction<UserState>>;
}

export const UserContext = React.createContext({} as UserContextInterface);

export const UserContextProvider = ({ children }: any) => {
  const [user, setUser] = useState<UserState>(defaultUserState);

  const value = {
    user,
    setUser
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
