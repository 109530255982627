import React from 'react';

interface TextProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  label: string;
  reqError?: boolean;
}

export default function Text(props: TextProps) {
  const { disabled, error, label, name, onChange, onKeyDown, reqError, required, type, value } =
    props;

  const classNames = `form-group form-group-float m-b-0 flex-1 ${error ? 'has-error' : ''}`;

  return (
    <div className={classNames}>
      <input
        onChange={onChange}
        onKeyDown={onKeyDown}
        required={required}
        type={type}
        id={name}
        name={name}
        data-testid="input-text"
        className="form-control"
        placeholder=" "
        disabled={disabled}
        value={value}
      />
      <label htmlFor={name}>
        {reqError && (
          <span aria-label="required">
            <span aria-hidden="true">*</span>
          </span>
        )}
        {label}
      </label>
      {error && (
        <div
          className="text-xs form-group-error-msg"
          style={{ display: 'block', position: 'relative' }}>
          {error}
        </div>
      )}
    </div>
  );
}
