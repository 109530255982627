import React, { useState, useContext, useEffect } from 'react';
import { PreferenceContext } from '../../PreferenceContext';

const noop = () => {};

type AccordianItemProps = {
  defaultExpanded: boolean;
  headingKey: string;
  itemNumber: number;
  items: any;
};

export default function ({ defaultExpanded, headingKey, itemNumber, items }: AccordianItemProps) {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [allChecked, setAllChecked] = useState(false);
  const itemKeys = Object.keys(items).sort();
  const { translationsMap, domains, setDomains } = useContext(PreferenceContext);

  useEffect(() => {
    if (itemKeys.every((key: string) => domains[headingKey][key])) setAllChecked(true);
  }, [domains]);

  const toggleAll = () => {
    if (!isExpanded) setAllChecked(false);
    const toggledDomains = Object.keys(domains[headingKey]).reduce(
      (acc: any, curr: any) => ({ ...acc, [curr]: !allChecked }),
      {}
    );
    setDomains({ ...domains, [headingKey]: toggledDomains });
    setAllChecked(!allChecked);
    setIsExpanded(true);
  };

  const handleOnItemClick = (itemKey: string) => {
    if (!!domains[headingKey][itemKey]) setAllChecked(false);
    setDomains({
      ...domains,
      [headingKey]: {
        ...domains[headingKey],
        [itemKey]: !domains[headingKey][itemKey]
      }
    });
  };

  const Heading = () => (
    <div className="pref accordion-title text-xl display-flex" id={headingKey}>
      <input
        type="checkbox"
        className="pref form-checkbox form-checkbox-sm m-l-md p-r-0"
        name="traits"
        onChange={noop}
        checked={allChecked}
      />
      <label
        className="form-checkbox-label align-items-center p-r-0 m-l-sm "
        onClick={toggleAll}></label>
      <h2
        id={`item${itemNumber}-item${itemNumber}-tab`}
        aria-controls={`item${itemNumber}-item${itemNumber}-panel`}
        className="accordion-header"
        role="tab"
        aria-expanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}>
        {translationsMap.businessAreas[headingKey]}
      </h2>
    </div>
  );

  return (
    <div id="item0" className="accordion">
      <Heading />
      {isExpanded && (
        <div
          aria-labelledby={`item${itemNumber}-item${itemNumber}-tab`}
          className="accordion-panel space-children p-xs-x-sm p-b-0 p-t-0"
          role="tabpanel"
          style={{ display: 'block' }}
          aria-hidden="false">
          <div className="preference-form m-x-sm ">
            <div className="p-b-0 ">
              {itemKeys.map((itemKey: string, id: number) => (
                <div
                  key={id}
                  className="row p-y-sm m-x-sm border-b border-1 border-grey-lighter "
                  onClick={() => handleOnItemClick(itemKey)}>
                  <input
                    type="checkbox"
                    name="traits"
                    className="form-checkbox form-checkbox-sm"
                    onChange={noop}
                    checked={domains[headingKey][itemKey]}
                  />
                  <label className="form-checkbox-label align-items-center text-sm p-md-l-sm p-md-r p-y-xs">
                    {translationsMap.domains[itemKey]}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
