import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../common/Button';

export const MODAL_UNSUBSCRIBE = 'modal-unsubscribe';

type UnsubscribeModalProps = {
  yesAction: () => void;
  noAction: () => void;
};

export default function ({ yesAction, noAction }: UnsubscribeModalProps) {
  const { t } = useTranslation();

  return (
    <div className="modal modal-xs" id={MODAL_UNSUBSCRIBE} aria-hidden="true">
      <div className="modal-overlay" tabIndex={-1} data-micromodal-close="true">
        <div
          className="modal-content m-xs-x-md"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title-unsubscribe">
          <header className="modal-header  p-b-0 ">
            <h4 className="modal-title" id="modal-title-unsubscribe">
              <div className="item-flex p-b-0 text-sm">
                <p>{t('are_you_sure')}</p>
              </div>
            </h4>
            <button className="modal-close" aria-label="Close modal" data-micromodal-close="true" />
          </header>
          <main
            className="modal-body  p-t-0  space-children space-children-sm"
            id="modal-content-unsubscribe">
            <hr className="bg-grey-light" />
            <Button
              onClick={() => yesAction()}
              customClass="btn btn-primary btn-sm btn-block"
              text={t('yesText')}
            />
            <Button
              onClick={() => noAction()}
              customClass="btn btn-outline btn-sm btn-block"
              text={t('noText')}
            />
          </main>
        </div>
      </div>
    </div>
  );
}
