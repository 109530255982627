import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LOCALE, getTranslatedLanguages } from '../../locale';

import { PreferenceContext } from './PreferenceContext';
import Select from '../common/Inputs/Select';
import Checkbox from '../common/Inputs/Checkbox';

export default function PreferredLanguage(): JSX.Element {
  const { t } = useTranslation();
  const { preferredLocale, setPreferredLocale, acceptEnglish, setAcceptEnglish } =
    useContext(PreferenceContext);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setPreferredLocale(e.target.value);
  };

  const handleCheckboxChange = () => {
    setAcceptEnglish(!acceptEnglish);
  };

  const renderDefaultOptIn = () => {
    if (preferredLocale !== DEFAULT_LOCALE)
      return (
        <Checkbox
          label={t('accept_materials_english')}
          onChange={handleCheckboxChange}
          checked={acceptEnglish}
          value="defaultLocaleOptIn"
          customLabelClass="form-checkbox-label"
        />
      );
  };

  return (
    <>
      <div className="row m-x-0 p-t-md">
        <div className="col-xs-12 col-sm-4 p-l-0">
          <div className="form-group">
            <Select
              id={'preferred-language'}
              label={t('preferred_language')}
              options={getTranslatedLanguages()}
              onChange={handleSelectChange}
              value={preferredLocale}
            />
          </div>
        </div>
      </div>
      <div className="p-b-md row m-x-0">{renderDefaultOptIn()}</div>
    </>
  );
}
