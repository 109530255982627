import { AxiosResponse } from 'axios';
import { httpClient } from './utils';

const FIELD_MARSHAL_API_BASE =
  process.env.FIELD_MARSHAL_API_BASE ?? 'https://field-marshal.swi.siemens.com';

export type Company = {
  dunsNumber?: string;
  companyName: string;
  address?: string;
};

export type FMCountry = {
  label: string;
  code: string;
};

export type Country = {
  label: string;
  value: string;
};

export const getCompanyNameRecommendations = async (
  query: string = ''
): Promise<Array<Company>> => {
  const response: AxiosResponse<any, Company> = await httpClient({
    baseURL: FIELD_MARSHAL_API_BASE,
    url: `/company/name?q=${encodeURIComponent(query.toLowerCase())}&max=10`
  });

  return response.data;
};

export const getCountries = async (locale: string = 'en-US'): Promise<Array<FMCountry>> => {
  const response: AxiosResponse<any, FMCountry> = await httpClient({
    baseURL: FIELD_MARSHAL_API_BASE,
    url: `/country/all?locale=${locale}`
  });

  return response.data;
};
