import React from 'react';
import { useSearchParams } from 'react-router-dom';

export default function Logout(props: { text: string }) {
  const [_, setQueryParams] = useSearchParams();
  const { text } = props;

  const handleLogout = () => {
    setQueryParams({});
    window.disw?.account?.logout();
  };

  return (
    <a onClick={() => handleLogout()} className="cursor-pointer text-decoration-none text-700">
      {text}
    </a>
  );
}
