import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocale } from '../../locale';
import Button from '../common/Button';
import Text from '../common/Inputs/Text';
import { validateEmail } from '../../api';
import { NotificationType } from '../../@types/api';

export const VALID_EMAIL_REGEX =
  /^$|^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

type SignInFormProps = {
  setStatus: React.Dispatch<React.SetStateAction<NotificationType | null>>;
};

export default function SignInForm({ setStatus }: SignInFormProps) {
  const { t } = useTranslation();
  const email = useRef('');
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsValid(true);
    email.current = e.target.value;
  };

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    if (!email.current.match(VALID_EMAIL_REGEX) || email.current === '') {
      setIsValid(false);
      setIsLoading(false);
      return;
    }

    try {
      const notificationType = await validateEmail(email.current, getLocale());
      setStatus(notificationType);
    } catch (error) {
      setStatus('FAILURE');
    }
  };

  return (
    <form onSubmit={handleOnSubmit} noValidate={true}>
      <h3>{t('verify_your_email')}</h3>
      <p className="text-md-md">{t('enter_valid_email')}.</p>
      <div className="row">
        <div className="col-md-3 col-sm-6 col-xs-12">
          <div className="m-b-md">
            <Text
              reqError={!isValid}
              onChange={handleOnChange}
              error={isValid ? '' : t('enter_valid_email')}
              required={true}
              type="email"
              name="Email"
              label={t('email_address')}
              disabled={isLoading}
            />
          </div>
          <Button
            type="submit"
            text={t('submit_email')}
            disabled={isLoading}
            enableSpinner={isLoading}
          />
        </div>
      </div>
    </form>
  );
}
