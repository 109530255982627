import React, { useContext } from 'react';
import { PreferenceContext } from '../../PreferenceContext';
import AccordianItem from './AccordionItem';

export default () => {
  const {
    translationsMap: { businessAreas },
    domains
  } = useContext(PreferenceContext);

  const sortedBusinessAreas = Object.keys(businessAreas).sort((a: string, b: string) =>
    businessAreas[a] < businessAreas[b] ? -1 : 0
  );

  return (
    <div className="tabpanels m-l-0">
      <div className="TabContent">
        {sortedBusinessAreas.map((typeKey, itemNumber) => (
          <AccordianItem
            key={itemNumber}
            defaultExpanded={itemNumber === 0 ? true : false}
            headingKey={typeKey}
            itemNumber={itemNumber}
            items={domains[typeKey]}
          />
        ))}
      </div>
    </div>
  );
};
