import React, { useState, useEffect } from 'react';
import Form from './SignInForm';
import Notification from '../common/Notification';
import { NotificationType } from '../../@types/api';
import { useTranslation } from 'react-i18next';
import Button from '../common/Button';
import { page, PAGE_NAMES } from '../../analytics';

export default function SignIn() {
  const { t } = useTranslation();
  const [notificationType, setNotificationType] = useState<NotificationType | null>(null);

  const NOTIFICATION_MESSAGE = {
    SUCCESS: {
      heading: t('check_email'),
      body: t('email_success_message'),
      link: undefined
    },
    FAILURE: {
      heading: t('failure_message'),
      body: t('system_is_unavailable'),
      link: {
        text: 'notification_failure_cta',
        action: () => {
          setNotificationType(null);
        }
      }
    }
  };

  useEffect(() => {
    page(PAGE_NAMES.SIGN_IN);
  }, []);

  const PostSendEmail = () => {
    if (notificationType != 'SUCCESS' && notificationType != 'FAILURE') return <></>;

    const { heading, body, link } = NOTIFICATION_MESSAGE[notificationType];
    return (
      <Notification
        type={notificationType}
        heading={heading}
        body={body}
        link={link}
        closeDisabled={true}
      />
    );
  };

  const Body = () => {
    if (notificationType != 'SUCCESS' && notificationType != 'FAILURE')
      return (
        <>
          <Form setStatus={setNotificationType} />
          <>
            <h3 className="m-t-md">{t('have_siemens_account')}?</h3>
            <p>{t('already_have_an_account')}.</p>
            <div className="item-flex m-t">
              <Button onClick={() => window.disw?.account?.login()} text={t('log_in')} />
            </div>
          </>
        </>
      );

    return <PostSendEmail />;
  };

  return (
    <>
      <div className="p-t-sm">
        <p>
          {t('keep_up_to_date')}
          <a
            href="https://www.sw.siemens.com/en-US/trust-center"
            className="text-decoration-none text-700 cursor-pointer">
            {t('privacy_policy')}
          </a>
          .
        </p>
      </div>
      <div className="p-md-y-md m-b-md">
        <Body />
      </div>
    </>
  );
}
