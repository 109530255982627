import i18next from 'i18next';
import { DEFAULT_LOCALE } from '../../locale';
import { initReactI18next } from 'react-i18next';
import { getTranslations } from '../../api';
import { TranslationMap } from '../../@types/api';

export const initTranslations = async (locale: string): Promise<void> => {
  i18next.use(initReactI18next).init({
    lng: locale,
    debug: process.env.DISW_ENVIRONMENT === 'dev',
    fallbackLng: DEFAULT_LOCALE,
    resources: await getTranslationsMap(locale),
    interpolation: {
      escapeValue: false
    }
  });
};

const getTranslationsMap = async (locale: string): Promise<TranslationMap> => {
  const defaultTranslations = await getTranslations(DEFAULT_LOCALE);
  const selectedTranslations = locale !== DEFAULT_LOCALE ? await getTranslations(locale) : null;

  const responses = selectedTranslations
    ? [defaultTranslations, selectedTranslations]
    : [defaultTranslations];

  return responses.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.data.locale]: {
        translation: curr.data
      }
    }),
    {}
  );
};
