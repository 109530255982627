import React, { useContext } from 'react';

import { AppContext } from './app/AppContext';
import SignInForm from './SignIn';
import Unsubscribe from './Unsubscribe';
import UnsubscribeByEmail from './UnsubscribeByEmail';
import SelectPreferences from './SelectPreferences';
import Header from './common/Header';
import ErrorBoundary from './common/ErrorBoundary';
import Embargoed from './Embargoed';
import SubscriptionConfirmation from './SubscriptionConfirmation';

export default function Layout() {
  const { step } = useContext(AppContext);

  const Body = {
    signIn: SignInForm,
    selectPreferences: SelectPreferences,
    unsubscribe: Unsubscribe,
    unsubscribeByEmail: UnsubscribeByEmail,
    failure: () => <>System Failed</>, // TODO:Add system failed step
    embargoed: Embargoed,
    subscriptionConfirmation: SubscriptionConfirmation
  }[step];

  return (
    <div className="container p-y-xl m-t-xl">
      <ErrorBoundary>
        <Header />
        <Body />
      </ErrorBoundary>
    </div>
  );
}
