import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

interface HttpClientConfig extends AxiosRequestConfig {
  noCache?: boolean;
  baseURL: string;
}

export const httpClient = async ({
  method = 'get',
  url = '/',
  headers = {},
  data = {},
  baseURL,
  noCache = true
}: HttpClientConfig): Promise<AxiosResponse<any, any>> => {
  if (noCache) {
    headers = {
      ...headers,
      'Cache-Control': 'no-cache'
    };
  }

  try {
    return await axios({
      method,
      url,
      baseURL,
      responseType: 'json',
      headers,
      data
    });
  } catch (error) {
    throw error;
  }
};
