export enum PAGE_NAMES {
  SIGN_IN = 'Sign In',
  SELECT_PREFERENCES = 'Select Preferences',
  UNSUBSCRIBE = 'Unsubscribe',
  SUBSCRIPTION_CONFIRMATION = 'Subscription Confirmation'
}

export const page = (pageName: PAGE_NAMES) => {
  window.analytics.page(undefined, pageName);
};
