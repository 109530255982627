import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MicroModal from 'micromodal';
import { UserContext } from '../app/UserContext';
import UnsubscribeModal, { MODAL_UNSUBSCRIBE } from './UnsubscribeModal';
import Notification from '../common/Notification';
import Button from '../common/Button';
import Checkbox from '../common/Inputs/Checkbox';
import { NotificationType, unsubscribeEmailPayload } from '../../@types/api';
import { unsubscribe } from '../../api';

export default function UnsubscribeForm() {
  const [boxChecked, setBoxChecked] = useState<boolean>(false);
  const [notificationType, setNotificationType] = useState<NotificationType | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    user: { contactId, sessionId, jwt, uid, sig }
  } = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    MicroModal.init();
  }, []);

  const showModal = () => {
    MicroModal.show(MODAL_UNSUBSCRIBE);
  };

  const hideModal = () => {
    MicroModal.close(MODAL_UNSUBSCRIBE);
  };

  const handleModalYesClick = async () => {
    hideModal();
    setIsLoading(true);

    const payload: unsubscribeEmailPayload = {
      contactId,
      sessionId,
      jwt,
      uid,
      sig
    };
    try {
      const notificationType = await unsubscribe(payload);
      setNotificationType(notificationType);
    } catch (error) {
      setNotificationType('FAILURE');
    }
  };

  const NOTIFICATION_MESSAGE = {
    SUCCESS: {
      heading: t('received_request'),
      body: t('request_to_be_processed'),
      link: undefined
    },
    FAILURE: {
      heading: t('failure_message'),
      body: t('system_is_unavailable'),
      link: {
        text: 'notification_failure_cta',
        action: () => {
          setIsLoading(false);
          setNotificationType(null);
        }
      }
    }
  };

  const PostSendEmail = () => {
    if (notificationType != 'SUCCESS' && notificationType != 'FAILURE') return <></>;

    const { heading, body, link } = NOTIFICATION_MESSAGE[notificationType];
    return (
      <Notification
        type={notificationType}
        heading={heading}
        body={body}
        link={link}
        closeDisabled={true}
      />
    );
  };

  return (
    <>
      {notificationType && (
        <div className="p-t-md ">
          <PostSendEmail />
        </div>
      )}
      {!notificationType && (
        <div className="row m-x-0  p-t-md p-l-0">
          <div className="col-xs-12 p-l-0">
            <div className="form-group">
              <div className="p-t-sm">
                <Checkbox
                  value={'remove'}
                  onChange={() => setBoxChecked(!boxChecked)}
                  checked={boxChecked}
                  customLabelClass={`form-checkbox-label ${isLoading ? 'pointer-none' : ''}`}
                  label={t('remove_me_marcom_emails')}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {!notificationType && (
        <div className="row m-x-0 p-y-md align-items-center ">
          <Button
            enableSpinner={isLoading}
            disabled={!boxChecked || isLoading}
            text={t('save')}
            customClass={`btn btn-primary btn-sm  mobile-btn-block`}
            onClick={boxChecked ? () => showModal() : undefined}
          />
        </div>
      )}
      <UnsubscribeModal yesAction={handleModalYesClick} noAction={hideModal} />
    </>
  );
}
