import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocale } from '../../locale';

import { postUserPreferences } from '../../api';
import { NotificationType, postPreferencePayload } from '../../@types/api';
import { PreferenceContext } from './PreferenceContext';
import Button from '../common/Button';
import Notification from '../common/Notification';
import { UserContext } from '../app/UserContext';
import { AxiosError } from 'axios';

export default function SavePreferencesButton(): JSX.Element {
  const { t } = useTranslation();
  const { preferredLocale, acceptEnglish, industries, domains, formValues, dunsNumber } =
    useContext(PreferenceContext);
  const { setUser, user } = useContext(UserContext);
  const { contactId, sessionId, jwt, uid, sig, fields } = user;
  const [notificationType, setNotificationType] = useState<NotificationType | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = (): boolean => {
    for (let i = 0; i < fields.length; i += 1) {
      if (!Boolean(formValues[fields[i]])) {
        return false;
      }
    }
    return true;
  };

  const savePreferences = async () => {
    if (!validateForm()) {
      return;
    }
    setNotificationType(null);
    setIsLoading(true);

    const payload: postPreferencePayload = {
      preferences: buildFormData(),
      contactId,
      sessionId,
      jwt,
      uid,
      sig
    };

    try {
      const notificationType = await postUserPreferences(payload);
      setNotificationType(notificationType);
      setUser({
        ...user,
        fields: [],
        preferences: {
          ...user.preferences,
          ...(payload.preferences.domains && { domains: payload.preferences.domains }),
          ...(payload.preferences.industries && { industries: payload.preferences.industries })
        }
      });
    } catch (error: unknown) {
      const notificationType =
        (error instanceof AxiosError && error.response?.data.notificationType) || 'FAILURE';
      setNotificationType(notificationType);
    }

    setIsLoading(false);
  };

  const notificationCopy = {
    SUCCESS: {
      heading: 'notification_success_heading',
      body: 'notification_success_body',
      link: undefined,
      closeDisabled: false
    },
    FAILURE: {
      heading: 'notification_failure_heading',
      body: 'notification_failure_body',
      link: {
        text: 'notification_failure_cta',
        action: () => setNotificationType(null)
      },
      closeDisabled: false
    },
    EMBARGOED: {
      heading: 'embargo_title',
      body: 'embargo_content',
      link: undefined,
      closeDisabled: true
    }
  };

  const PostSaveNotice = () => {
    if (
      notificationType != 'SUCCESS' &&
      notificationType != 'FAILURE' &&
      notificationType != 'EMBARGOED'
    )
      return <></>;

    const { heading, body, link, closeDisabled } = notificationCopy[notificationType];
    return (
      <Notification
        type={notificationType}
        heading={heading}
        body={body}
        link={link}
        closeDisabled={closeDisabled}
      />
    );
  };

  const buildFormData = () => {
    return {
      ...formValues,
      dunsNumber: dunsNumber || undefined,
      acceptEnglish,
      preferredLocale,
      industries: { ...industries },
      domains: Object.keys(domains).reduce(
        (acc: any, curr: string) => ({ ...acc, ...domains[curr] }),
        {}
      ),
      locale: getLocale()
    };
  };

  return (
    <>
      <div className="row m-x-0 p-b-md p-l-0 m-t-lg ">
        <Button
          disabled={isLoading || !validateForm()}
          enableSpinner={isLoading}
          text={t('save_preferences')}
          onClick={savePreferences}
        />
      </div>
      <PostSaveNotice />
    </>
  );
}
