import React from 'react';
import Notification from './common/Notification';
import { useTranslation } from 'react-i18next';

export default function Embargoed() {
  const { t } = useTranslation();

  return (
    <div className="p-t-xl">
      <Notification
        type={'EMBARGOED'}
        heading={t('embargo_title')}
        body={t('embargo_content')}
        closeDisabled={true}
      />
    </div>
  );
}
